<template>
    <div>
        <v-container fluid class="pt-0 mt-5">
            <v-row>
                <v-col cols="12">
                    <v-alert :dismissible="false" class="light-green accent-4 white--text" dark border="left" icon="fas fa-calendar-alt">
                        <v-row no-gutters class="align-center mt-n1 mb-n1">
                        	<v-col cols="10" class="pa-0">
								<span>Stats Summary</span>
                        	</v-col>
                        	<v-col cols="2" class="pa-0">
                        		<v-menu dense v-model="endDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
						        	<template v-slot:activator="{ on, attrs }">
						        		<v-text-field dense v-model="computedDateFormatted" prepend-icon="fas fa-calendar-alt" hide-details="false" readonly v-bind="attrs" v-on="on"></v-text-field>
						        	</template>
						          	<v-date-picker dense v-model="endDate" no-title @input="endDateMenu = false"></v-date-picker>
						        </v-menu>
                        	</v-col>
                        </v-row>
                    </v-alert>
                </v-col>
                <v-col cols="12" v-if="loading">
                    <v-skeleton-loader dense type="table-row-divider@10" class="mx-auto"></v-skeleton-loader>
                </v-col>
                <v-col class="d-flex flex-nowrap overflow-auto" cols="12" v-if="!loading">
	                <div v-for="(currency, index) in currencies" :key="index">
	                	<v-row>
	                		<v-col cols="12" class="text-center px-0 pb-0">
		                		{{ currency }}
		                	</v-col>
							<v-col cols="12" class="py-0 px-8">
								<v-divider></v-divider>
								<v-data-table dense :headers="headers" :items="totalData[currency].statsList" disable-sort hide-default-footer v-if="!loading" class="space-nowrap">
									<!-- 表数据 -->
									<template v-slot:body>
										<tbody>
											<tr v-if="totalData[currency].statsList.length == 0">
												<td colspan="9" class="text-center">No Data Available</td>
											</tr>
											<tr v-if="totalData[currency].statsList.length > 0" v-for="(stats, index) in totalData[currency].statsList" :key="index">
												<td class="font-weight-bold px-2">{{ stats.date }}</td>
												<td class="text-right px-2">{{ common.formatNum(stats.atr3_k) }}</td>
												<td class="text-right px-2">{{ common.formatNum(stats.atr14_k) }}</td>
												<td class="text-right px-2">{{ common.formatNum(stats.atr3_ha) }}</td>
												<td class="text-right px-2">{{ common.formatNum(stats.atr14_ha) }}</td>
												<td class="text-right px-2">{{ common.formatNum(stats.atr14_ha_avg100) }}</td>
												<td class="text-right px-2">{{ common.formatNum(stats.ema80_ha) }}</td>
												<td class="text-right px-2">{{ common.formatNum(stats.ema140_ha) }}</td>
												<td class="text-right px-2">{{ common.formatNum(stats.ema200_ha) }}</td>
												<td class="text-right px-2">{{ common.formatNum(stats.marketTypes) }}</td>
												<!-- *********************************MaxOpen********************************* -->
												<td class="text-right px-2">
													<span v-if="stats.maxOpen != null">
														{{ common.formatNum(stats.maxOpen.longMaxOpen) }}
													</span>
													<span v-else>--</span>
												</td>
												<td class="text-right px-2">
													<span v-if="stats.maxOpen != null">
														{{ common.formatNum(stats.maxOpen.shortMaxOpen) }}
													</span>
													<span v-else>--</span>
												</td>
												<!-- *********************************OKEx Futures********************************* -->
												<td class="text-right px-2">
													<span v-if="stats.okexLiquidationPrice != null">
														{{ common.formatNum(stats.okexLiquidationPrice.longLiquidationPrice) }}
													</span>
													<span v-else>--</span>
												</td>
												<td class="text-right px-2">
													<span v-if="stats.okexLiquidationPrice != null">
														{{ common.formatNum(stats.okexLiquidationPrice.shortLiquidationPrice) }}
													</span>
													<span v-else>--</span>
												</td>
												<!-- <td class="text-right px-2">
													<span v-if="stats.okexMaxOpen != null">
														{{ common.formatNum(stats.okexMaxOpen.longMaxOpen) }}
													</span>
													<span v-else>--</span>
												</td>
												<td class="text-right px-2">
													<span v-if="stats.okexMaxOpen != null">
														{{ common.formatNum(stats.okexMaxOpen.shortMaxOpen) }}
													</span>
													<span v-else>--</span>
												</td> -->
												<!-- *********************************Kraken Futures********************************* -->
												<td class="text-right px-2">
													<span v-if="stats.krakenLiquidationPrice != null">
														{{ common.formatNum((stats.krakenLiquidationPrice.longLiquidationPrice).toFixed(futuresDecimalNumbers['PI_' + currency + 'USD'])) }}
													</span>
													<span v-else>--</span>
												</td>
												<td class="text-right px-2">
													<span v-if="stats.krakenLiquidationPrice != null">
														{{ common.formatNum((stats.krakenLiquidationPrice.shortLiquidationPrice).toFixed(futuresDecimalNumbers['PI_' + currency + 'USD'])) }}
													</span>
													<span v-else>--</span>
												</td>
												<!-- <td class="text-right px-2">
													<span v-if="stats.krakenMaxOpen != null">
														{{ common.formatNum(stats.krakenMaxOpen.longMaxOpen) }}
													</span>
													<span v-else>--</span>
												</td>
												<td class="text-right px-2">
													<span v-if="stats.krakenMaxOpen != null">
														{{ common.formatNum(stats.krakenMaxOpen.shortMaxOpen) }}
													</span>
													<span v-else>--</span>
												</td> -->
												<!-- *********************************FTX USDT Swap********************************* -->
												<td class="text-right px-2">
													<span v-if="stats.ftxLiquidationPrice != null">
														{{ common.formatNum((stats.ftxLiquidationPrice.longLiquidationPrice).toFixed(getSwapUsdtDecimalPlace('ftx', currency + '-USDT-SWAP'))) }}
													</span>
													<span v-else>--</span>
												</td>
												<td class="text-right px-2">
													<span v-if="stats.ftxLiquidationPrice != null">
														{{ common.formatNum((stats.ftxLiquidationPrice.shortLiquidationPrice).toFixed(getSwapUsdtDecimalPlace('ftx', currency + '-USDT-SWAP'))) }}
													</span>
													<span v-else>--</span>
												</td>
												<!-- <td class="text-right px-2">
													<span v-if="stats.ftxMaxOpen != null">
														{{ common.formatNum(stats.ftxMaxOpen.longMaxOpen) }}
													</span>
													<span v-else>--</span>
												</td>
												<td class="text-right px-2">
													<span v-if="stats.ftxMaxOpen != null">
														{{ common.formatNum(stats.ftxMaxOpen.shortMaxOpen) }}
													</span>
													<span v-else>--</span>
												</td> -->
												<!-- *********************************Candle********************************* -->
												<td class="text-right px-2" v-if="stats.candle != null">{{ common.formatNum(stats.candle.open) }}</td>
												<td class="text-right px-2" v-if="stats.candle != null">{{ common.formatNum(stats.candle.high) }}</td>
												<td class="text-right px-2" v-if="stats.candle != null">{{ common.formatNum(stats.candle.low) }}</td>
												<td class="text-right px-2" v-if="stats.candle != null">{{ common.formatNum(stats.candle.close) }}</td>
											</tr>
										</tbody>
									</template>
								</v-data-table>
							</v-col>
							<v-col cols="12" class="px-8 mt-10">
								<v-data-table dense :headers="highAndLowHeaders" :items="totalData[currency].highAndLowList" disable-sort hide-default-footer v-if="!loading">
									<!-- 表数据 -->
									<template v-slot:body>
										<tbody>
											<tr v-if="totalData[currency].highAndLowList.length == 0">
												<td colspan="9" class="text-center">No Data Available</td>
											</tr>
											<tr v-if="totalData[currency].highAndLowList.length > 0" v-for="(stats, index) in totalData[currency].highAndLowList" :key="index">
												<td class="font-weight-bold">{{ stats.name }}</td>
												<td>{{ stats.valueDate }}</td>
												<td class="text-right">{{ common.formatNum(stats.value) }}</td>
												<td class="text-right">{{ common.formatNum(stats.ema80_ha) }}</td>
												<td class="text-right">{{ common.formatNum(stats.atr14_ha) }}</td>
											</tr>
										</tbody>
									</template>
								</v-data-table>
							</v-col>
		                </v-row>
					</div>
				</v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Vue from 'vue';
    import config from '../../config';
    import { mapGetters } from "vuex";
    export default {
        data() {
            return {
                headers: [
                	{ text: 'Date', class: 'px-2' },
                	{ text: 'ATR3-K', align: 'end', class: 'px-2'  },
                    { text: 'ATR14-K', align: 'end', class: 'px-2' },
					{ text: 'ATR3-HA', align: 'end', class: 'px-2' },
					{ text: 'ATR14-HA', align: 'end', class: 'px-2' },
					{ text: 'ATR14-HA Avg100', align: 'end', class: 'px-2' },
					{ text: 'EMA80-HA', align: 'end', class: 'px-2' },
					{ text: 'EMA140-HA', align: 'end', class: 'px-2' },
					{ text: 'EMA200-HA', align: 'end', class: 'px-2' },
					{ text: 'Market Types', align: 'end', class: 'px-2' },
					{ text: 'MOL', align: 'end', class: 'px-2' },
					{ text: 'MOS', align: 'end', class: 'px-2' },
					{ text: '(O)LPL', align: 'end', class: 'px-2' },
					{ text: '(O)LPS', align: 'end', class: 'px-2' },
					{ text: '(K)LPL', align: 'end', class: 'px-2' },
					{ text: '(K)LPS', align: 'end', class: 'px-2' },
					{ text: '(F)LPL', align: 'end', class: 'px-2' },
					{ text: '(F)LPS', align: 'end', class: 'px-2' },
					{ text: 'O', align: 'end', class: 'px-2' },
					{ text: 'H', align: 'end', class: 'px-2' },
					{ text: 'L', align: 'end', class: 'px-2' },
					{ text: 'C', align: 'end', class: 'px-2' }
                ],
                highAndLowHeaders: [
                	{ text: 'Name' },
                	{ text: 'Date' },
                	{ text: 'Value', align: 'end' },
					{ text: 'EMA80-HA', align: 'end' },
					{ text: 'ATR14-HA', align: 'end' },
                ],
                project: null,
                currencies: [],
                totalData: {},
				loading:false,
				endDateMenu: false,
				endDate: new Date(new Date().getTime() - 86400000).toISOString().substr(0, 10),
            }
        },
        computed: {
            ...mapGetters(['user', 'futuresDecimalNumbers', 'swapUsdtSymbolInfos']),
            computedDateFormatted () {
		        return this.formatDate(this.endDate)
		    },
        },
        watch: {
            endDate(val){
            	this.getStats();
            }
        },
        mounted() {
            if (!this.user.token) {
                this.$router.push({ name: 'login' })
            };
            this.getAllIndicatorStatsCurrencies();
        },
        created() {
           
        },
        methods: {
        	// 查询所有币种
        	getAllIndicatorStatsCurrencies(){
		       	let api = config.baseUrl + '/symbol/public/currencies/indicator/stats';
		       	this.$http.get(api).then(response => {
	               if (response.data.code == 200) {
	                    this.currencies = response.data.result;
	                    let totalData = {};
	                    for(let i = 0, size = this.currencies.length; i < size; i++){
	                   		totalData[this.currencies[i]] = {
		                		statsList: [],
		                		highAndLowList:[]
		                	};
	                    }
	                    this.totalData = totalData;
	                    this.getStats();
	                }
	            })
        	},
        	// 获取数据
		   	getStats() {
			   this.loading = true;
			   this.statsList = [];
			   let startDate = new Date(new Date(this.endDate).getTime() - 86400000 * 3).toISOString().substr(0, 10);
			   let _this = this;
			   for (let i = 0; i < this.currencies.length; i++) {
				    // 每个请求延迟500依次毫秒后发出
					// 直接使用setTimeout这个定时器会等待for循环执行完成后再统一执行，是不正确的。
					// 再下面的代码中，加了一个立即执行的匿名函数，并且将for循环的i作为实参传入进去。这样，setTimeout就会被立即执行，而不会等待。
					(function(i) {
						setTimeout(() => {
							let api = config.baseUrl + '/indicator/stats/public/stats/summary?currency=' + _this.currencies[i] + '&startDate=' + startDate + '&endDate=' + _this.endDate;
							_this.$http.get(api).then(response => {
								_this.loading = false;
								if (response.data.code == 200) {
									_this.totalData[_this.currencies[i]].statsList = response.data.result.data;
									_this.totalData[_this.currencies[i]].highAndLowList = response.data.result.highAndLow;
								}
							})
						}, i * 2000);
					})(i)
			   }
		   	},
		   	formatDate (date) {
		        if (!date) return null
		        const [year, month, day] = date.split('-')
		        return `${day}/${month}/${year}`
		    },
			// 获取小数位
			getSwapUsdtDecimalPlace(exchange, symbol){
				if (exchange == null || symbol == null) {
					return 0;
				}
				let currentExchangeSwapUsdtSymbolInfos = this.swapUsdtSymbolInfos[exchange.toLowerCase()];
				for(let i = 0, len = currentExchangeSwapUsdtSymbolInfos.length; i < len; i++){
					if (currentExchangeSwapUsdtSymbolInfos[i].symbol == symbol) {
						return currentExchangeSwapUsdtSymbolInfos[i].priceDecimalPlace;
					}
				}
				return 0;
			},
        }
    }
</script>

<style scoped>
.overflow-auto {
	overflow: auto;
}
.space-nowrap {
	width:100%;
	white-space: nowrap;
}
</style>